import { getLink } from '@/utils/getLink';

export const footerListData = [
  {
    title: 'Corporate',
    links: [
      {
        text: 'About us',
        url: 'https://www.1800flowersinc.com/about-us',
      },
      {
        text: 'Our brands',
        url: 'https://www.1800flowersinc.com/our-brands',
      },
      {
        text: 'Social responsibility',
        url: 'https://www.1800flowersinc.com/social-responsibility',
      },
      {
        text: 'Careers',
        url: 'https://www.1800flowersinc.com/careers',
      },
      {
        text: 'News & media',
        url: 'https://www.1800flowersinc.com/news-and-media',
      },
      {
        text: 'Investors',
        url: 'https://www.1800flowersinc.com/investors',
      },
    ],
  },
  {
    title: 'Helpful FAQs',
    links: [
      {
        text: 'Our W-9 form',
        url: getLink('downloadW9'),
      },
      {
        text: 'Shipping tables',
        url: getLink('link', { url: 'shipping-tables', hasHtml: false }),
      },
      {
        text: 'Allergy warning',
        url: 'https://www.1800flowers.com/customer-service-faq?active-tab=allergyWarning',
      },
      {
        text: 'Smile guarantee',
        url: 'https://www.1800flowers.com/customer-service-faq?active-tab=customerService',
      },
      {
        text: 'Become an affiliate',
        url: 'https://www.1800flowers.com/become-an-affiliate',
      },
      {
        text: 'Customer service hub',
        url: getLink('customerService', { hasHtml: false }),
      },
    ],
  },
  {
    title: 'Business Solutions and Services',
    links: [
      {
        text: 'Large enterprises',
        url: '/enterprise',
        isLocal: true,
      },
      {
        text: 'Small business solutions',
        url: '/business',
        isLocal: true,
      },
      {
        text: 'SmartGift® for Business',
        url: 'https://www.smartgift.com/',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        text: 'Celebrations Chatter podcast',
        url: 'https://www.youtube.com/@celebrationschatter',
      },
      {
        text: 'Business gifting blog',
        url: 'https://www.smartgift.com/articles',
      },
      {
        text: "Jim's corner",
        url: 'https://www.1800flowers.com/articles/jims-corner',
      },
    ],
  },
];
